import React, { useEffect } from "react";
import Box from "@mui/material/Box";

import "./App.css";
import LoginForm from "./Pages/Common/Login/loginFrm";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import MainLayout from "./Pages/Common/Main/MainLayout";
import MRBCDefault from "./Pages/Common/Main/mrbc/mrbc";
import MeterReadingComponent from "./Pages/Common/Main/mrbc/MrbcItems/MeterReading";
import BillCollectionComponent from "./Pages/Common/Main/mrbc/MrbcItems/BillCollection";
import BCDashboard from "./Pages/Common/Main/mrbc/MrbcItems/BCDashboard";
import MRDashboard from "./Pages/Common/Main/mrbc/MrbcItems/MRDashboard";
import ControlsComponent from "./Pages/Common/Main/mrbc/MrbcItems/Controls";
import SubUsersComponent from "./Pages/Common/Main/subUsers";
import ForgetPasswordComponent from "./Pages/Common/ForgetPassword";
import SuperAdminsComponent from "./Pages/Common/Main/superAdmin";
import SuperUsersComponent from "./Pages/Common/Main/superAdmin/Users";
import SuperPagesComponent from "./Pages/Common/Main/superAdmin/Pages";
import TicketsComponent from "./Pages/Common/Main/chatTickets";
import TicketListComponent from "./Pages/Common/Main/chatTickets/ticketList";
import DashboardListComponent from "./Pages/Common/Main/chatTickets/dashboard";
import Cookies from "universal-cookie";
import HumanResoursesComponent from "./Pages/Common/Main/humanResource";
import AttendanceComponent from "./Pages/Common/Main/humanResource/Attendance";
import MRBCEmissionsComponent from "./Pages/Common/Main/mrbcEmissions";
import ImportEmissionsComponent from "./Pages/Common/Main/mrbcEmissions/ImportEmissions";
import MRBCMVComponent from "./Pages/Common/Main/mrbcMV";
import MVAVISComponent from "./Pages/Common/Main/mrbcMV/DataEntry/AVIS";
import MasterInfoComponent from "./Pages/Common/Main/humanResource/AddressBook/MasterInfo";
import DepartmentsComponent from "./Pages/Common/Main/humanResource/AddressBook/Departments";
import PositionsComponent from "./Pages/Common/Main/humanResource/AddressBook/Positions";
import UsersSecurityComponent from "./Pages/Common/Main/superAdmin/UsersSecurity";
import LeaveRequestComponent from "./Pages/Common/Main/humanResource/LeaveRequest";
import MyHRComponent from "./Pages/Common/Main/myHR";
import MyHRLeaveRequestComponent from "./Pages/Common/Main/myHR/LeaveRequest";
import SuperHRComponent from "./Pages/Common/Main/superHR";
import SuperHRLeaveRequestComponent from "./Pages/Common/Main/superHR/LeaveRequest";
import HolidaysComponent from "./Pages/Common/Main/humanResource/Holidays";
import CRPOSAdminComponent from "./Pages/Common/Main/CRPOSAdmin";
import POSAdminComponent from "./Pages/Common/Main/CRPOSAdmin/POSAdmin";
import CRDBAdminComponent from "./Pages/Common/Main/CRPOSAdmin/CRDBAdmin";
import MMSJDEIntegrationComponent from "./Pages/Common/Main/mrbcEmissions/MMSJDEIntegration";
import SocketIO from "./Services/SocketIO";
import {
  setSocket,
  setSocketAdmin,
  setSocketHR,
  setSocketMRBC,
} from "./Services/redux/reducers/userMenuReducer";
import AttendanceAdminComponent from "./Pages/Common/Main/attendanceAdmin";
import AttendanceMachine from "./Pages/Common/Main/attendanceAdmin/AttendanceMachine";

export const UserContext = React.createContext();

function App() {
  const [theme, colorMode] = useMode();
  const user = useSelector((state) => state.menu.user);
  const userAll = useSelector((state) => state.menu.userAll);
  const socketLabels = useSelector((state) => state.menu.socketLabels);
  const socket = useSelector((state) => state.menu.socket);
  const socketmrbc = useSelector((state) => state.menu.socketmrbc);
  const socketHR = useSelector((state) => state.menu.socketHR);
  const socketadmin = useSelector((state) => state.menu.socketadmin);
  const userMenu = useSelector((state) => state.menu.userMenu);
  const location = useLocation();
  const cookies = new Cookies();
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) {
      socket.on("test", function (a, b) {
        // console.log(typeof a);
        // console.log(a);
        // console.log(b);
        b({ kkjk: "yes" });
      });
    }
  }, [socket]);

  const filterLabels = (label) => {
    const filtered = socketLabels?.filter((e) => {
      if (e?.indexOf(",") > -1) {
        const isTrue =
          e?.split(", ")?.[0] === label || e?.split(", ")?.[1] === label;
        return isTrue;
      } else {
        return e?.includes(label);
      }
    });
    return filtered?.length > 0;
  };

  const connection = (label, socket, path, setSocketAction) => {
    if (filterLabels(label) && !socket && user) {
      const socket2 = SocketIO(
        userAll?.token,
        path,
        label === "all" ? true : false
      ).connect();

      if (socket2 !== socket) {
        dispatch(setSocketAction(socket2));

        socket2.on("connect", () => {
          console.log(`Socket ${label} connected`);
          console.log(socket2);
        });

        socket2.on("disconnect", () => {
          console.log(`Socket ${label} disconnected`);
        });
      }
    }
  };

  useEffect(() => {
    connection(
      "mrbc",
      socketmrbc,
      process.env.REACT_APP_API_SOCKETIOMRBC_PATH,
      setSocketMRBC
    );
  }, [socketmrbc]);

  useEffect(() => {
    connection(
      "hr",
      socketmrbc,
      process.env.REACT_APP_API_SOCKETIOHR_PATH,
      setSocketHR
    );
  }, [socketHR]);

  useEffect(() => {
    connection(
      "admin",
      socketadmin,
      process.env.REACT_APP_API_SOCKETIOADMIN_PATH,
      setSocketAdmin
    );
  }, [socketadmin]);

  useEffect(() => {
    if (!socket && user) {
      const socket = SocketIO(
        userAll?.token,
        process.env.REACT_APP_API_SOCKETIO_PATH,
        true
      );
      dispatch(setSocket(socket));
    }
  }, [socket]);

  const pages = [
    {
      path: "/",
      element: !user ? (
        <LoginForm />
      ) : (
        <Navigate to={cookies.get("_location")} />
      ),
      childs: [],
    },
    {
      path: "/forgetPassword",
      element: <ForgetPasswordComponent />,
      childs: [],
    },
    {
      path: "/main/*",
      element: user ? (
        <MainLayout
          location={location?.pathname}
          state={
            location?.state !== null ? location?.state : userMenu?.[0]?.sections
          }
        />
      ) : (
        <Navigate to="/" />
      ),
      childs: [
        {
          path: "spvlvmrbc01",
          element: user ? <MRBCDefault /> : <Navigate to="/" />,
          childs: [
            {
              path: "spvlvmrbc01a",
              element: user ? <MeterReadingComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "spvlvmrbc01b",
              element: user ? <BillCollectionComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "spvlvmrbc01c",
              element: user ? <BCDashboard /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "spvlvmrbc01d",
              element: user ? <MRDashboard /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "spvlvmrbc01e",
              element: user ? <ControlsComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
        {
          path: "admindspsub",
          element: <SubUsersComponent />,
          childs: [],
        },
        {
          path: "superadmin01",
          element: user ? <SuperAdminsComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "superadmin01a",
              element: user ? <SuperPagesComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "superadmin01b",
              element: user ? <SuperUsersComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "superadmin01c",
              element: user ? <UsersSecurityComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
        {
          path: "chatickets01",
          element: user ? <TicketsComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "chatickets01a",
              element: user ? <TicketListComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "chatickets01b",
              element: user ? <DashboardListComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
        {
          path: "humanresources01",
          element: user ? <HumanResoursesComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "humanresources01/humanresources01a",
              element: user ? <AttendanceComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "humanresources01/humanresources01e",
              element: user ? <LeaveRequestComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "humanresources01/humanresources01f",
              element: user ? <HolidaysComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "humanresources01/humanresources01b",
              element: user ? <MasterInfoComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "humanresources01/humanresources01c",
              element: user ? <DepartmentsComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "humanresources01/humanresources01d",
              element: user ? <PositionsComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
        {
          path: "mrbcadmin01",
          element: user ? <MRBCEmissionsComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "mrbcadmin01/mrbcadmin01a",
              element: user ? (
                <ImportEmissionsComponent />
              ) : (
                <Navigate to="/" />
              ),
              childs: [],
            },
            {
              path: "mrbcadmin01/mrbcadmin01b",
              element: user ? (
                <MMSJDEIntegrationComponent />
              ) : (
                <Navigate to="/" />
              ),
              childs: [],
            },
          ],
        },
        {
          path: "mvmrbc01",
          element: user ? <MRBCMVComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "mvmrbc01/mvmrbc01a",
              element: user ? <MVAVISComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
        {
          path: "myHR01",
          element: user ? <MyHRComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "myHR01/myHR01a",
              element: user ? (
                <MyHRLeaveRequestComponent />
              ) : (
                <Navigate to="/" />
              ),
              childs: [],
            },
          ],
        },
        {
          path: "supervisorHR01",
          element: user ? <SuperHRComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "supervisorHR01/supervisorHR01a",
              element: user ? (
                <SuperHRLeaveRequestComponent />
              ) : (
                <Navigate to="/" />
              ),
              childs: [],
            },
          ],
        },
        {
          path: "mrbcposadmin01",
          element: user ? <CRPOSAdminComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "mrbcposadmin01/mrbcposadmin01a",
              element: user ? <CRDBAdminComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "mrbcposadmin01/mrbcposadmin01b",
              element: user ? <POSAdminComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
        {
          path: "attendanceadmin01",
          element: user ? <AttendanceAdminComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "attendanceadmin01/attendanceadmin01a",
              element: user ? <AttendanceMachine /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (location?.pathname !== "/" && location?.pathname !== undefined) {
      cookies.set("_location", location?.pathname, {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
    } else {
      cookies.set("_location", "/main", {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
    }
  }, [location?.pathname]);

  return (
    <Box sx={{ height: "100%", backgroundColor: "rgba(247, 245, 245, 0.651)" }}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Box bgcolor="rgba(247, 245, 245, 0.651)" width="100%" height="100%">
            {/* {user ? ( */}
            <Routes>
              {pages?.map((page, i) => {
                return (
                  <Route
                    // exact={true}
                    key={i}
                    path={page?.path}
                    element={page?.element}
                  >
                    {page?.childs?.map((child1, i) => {
                      return (
                        <Route
                          // exact={true}
                          key={i}
                          path={child1?.path}
                          element={child1?.element}
                        >
                          {child1?.childs?.map((child2, i) => {
                            return (
                              <Route
                                // exact={true}
                                key={i}
                                path={child2?.path}
                                element={child2?.element}
                                action={
                                  child2?.path === cookies.get("_location")
                                }
                              />
                            );
                          })}
                        </Route>
                      );
                    })}
                  </Route>
                );
              })}
            </Routes>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Box>
  );
}

export default App;
