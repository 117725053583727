import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const attendanceAdminSliceInitialState = {
  machineStatus: cookies.get("_machineStatus")
    ? cookies.get("_machineStatus")
    : [],
};

export const attendanceAdminSlice = createSlice({
  name: "attendanceadmin",
  initialState: attendanceAdminSliceInitialState,
  reducers: {
    setMachineStatus: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.machineStatus = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMachineStatus } = attendanceAdminSlice.actions;

export default attendanceAdminSlice.reducer;
