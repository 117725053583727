import React from "react";
import { Box, Button, Grid } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HistoryIcon from "@mui/icons-material/History";
import moment from "moment";

const MachineBox = ({ data }) => {
  const detailsSx = {
    marginBottom: "2%",
    display: "flex",
    alignItems: "center",
    color: "#064987",
  };

  return (
    <Button
      sx={{
        boxShadow:
          " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        width: "18%",
        backgroundColor: "#c3bdbd",
        paddingX: "1%",
        display: "flex",
        alignItems: "center",
        borderLeft:
          data?.BMCON === "connection"
            ? "25px solid #208e20"
            : "25px solid #be0606",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid sx={{ width: "100%" }}>
          <Grid sx={detailsSx} style={{ fontSize: "1rem", fontWeight: "1000" }}>
            <DriveFileRenameOutlineIcon />
            <Grid
              sx={{ marginLeft: "10%", color: "#064987", fontWeight: "bold" }}
            >
              {data?.BMDESC}
            </Grid>
          </Grid>{" "}
          <Grid sx={detailsSx}>
            <CreditCardIcon />
            <Grid
              sx={{ marginLeft: "10%", color: "#064987", fontWeight: "bold" }}
            >
              {data?.BMMACHINEID}
            </Grid>
          </Grid>{" "}
          <Grid sx={detailsSx}>
            <ApartmentIcon />
            <Grid
              sx={{ marginLeft: "10%", color: "#064987", fontWeight: "bold" }}
            >
              {data?.UVDESC}
            </Grid>
          </Grid>{" "}
          <Grid sx={detailsSx}>
            <HistoryIcon />
            <Grid
              sx={{ marginLeft: "10%", color: "#064987", fontWeight: "bold" }}
            >
              {moment(data?.BMLASTUPDATE).format("YYYY-MM-DD hh:mm A")}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
          container
          sx={{
            height: "50%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            img: {
              width: "70% !important",
            },
          }}
        >
          <Grid sx={{ width: "80%", textAlign: "left" }}>
            <img src="/faceRecognition.png" height={"100%"} />
          </Grid>
          <Grid sx={{ width: "20%" }}>
            <PowerSettingsNewIcon
              sx={{
                color: data?.BMCON === "connection" ? "#208e20" : "#be0606",
                width: "100%",
                height: "100%",
              }}
            />
          </Grid>
        </Grid>
        <Grid sx={{ paddingY: "5%", textAlign: "left" }}>
          <Grid sx={detailsSx}>
            <DriveFileRenameOutlineIcon />
            <Grid
              sx={{ marginLeft: "10%", color: "#064987", fontWeight: "bold" }}
            >
              {data?.BMDESC}
            </Grid>
          </Grid>{" "}
          <Grid sx={detailsSx}>
            <ApartmentIcon />
            <Grid
              sx={{ marginLeft: "10%", color: "#064987", fontWeight: "bold" }}
            >
              {data?.UVDESC}
            </Grid>
          </Grid>{" "}
          <Grid sx={detailsSx}>
            <HistoryIcon />
            <Grid
              sx={{ marginLeft: "10%", color: "#064987", fontWeight: "bold" }}
            >
              {moment(data?.BMLASTUPDATE).format("YYYY-MM-DD hh:mm A")}
            </Grid>
          </Grid>
        </Grid> */}
      </Box>
    </Button>
  );
};

export default MachineBox;
