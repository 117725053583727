import { Box, Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMachineStatus } from "../../../../../Services/redux/reducers/attendanceAdminReducer";
import Cookies from "universal-cookie";
import MachineBox from "./MachineBox";

const AttendanceMachine = () => {
  const socketHR = useSelector((state) => state.menu.socketHR);
  const machineStatus = useSelector(
    (state) => state.attendanceadmin.machineStatus
  );
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const getMachineConnectivity = () => {
    console.log("entered");
    socketHR?.emit(
      "dspinternal:web:hr:admin:v1",
      {
        request: "attendanceMachine",
        attendanceMachine: {
          type: "machineStatus",
        },
      },
      (response) => {
        dispatch(setMachineStatus(response?.data));
        cookies.set("_machineStatus", response?.data, {
          path: "/",
          expires: new Date(Date.now() + 2592000),
        });
        console.log(response?.data);
      }
    );
  };

  useEffect(() => {
    getMachineConnectivity();
  }, []);

  return (
    <Box sx={{ padding: "5%" }}>
      <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
        {machineStatus?.map((data) => (
          <MachineBox key={data?.BMMACHINEID} data={data} />
        ))}
      </Grid>
    </Box>
  );
};

export default AttendanceMachine;
